import {
  submitEvent,
  submitMove,
  trackOnNextPageImpression,
} from '@otto-ec/tracking-bct'
import { EventNames, TrackingPort } from '../ports'
import { LoggingServicePort } from '../../shared/ports'

export class TrackingBctAdapter<Features extends Record<string, [string]>>
  implements TrackingPort<Features>
{
  constructor(private readonly logger: LoggingServicePort) {}

  async track(eventName: EventNames, feature: Features): Promise<void> {
    try {
      switch (eventName) {
        case EventNames.PageImpression:
          return await trackOnNextPageImpression(feature)
        case EventNames.Event:
          return await submitEvent(feature)
        case EventNames.Move:
          return await submitMove(feature)
      }
    } catch (e) {
      this.logTrackingError(eventName, feature, e)
      return
    }
  }

  private logTrackingError(
    eventName: EventNames,
    feature: Features,
    e: unknown
  ) {
    this.logger.logMessage(`Error tracking event ${eventName}.`, feature, e)
  }
}
